import {
  Environment,
  Network,
  RecordSource,
  Store,
  QueryResponseCache
} from "relay-runtime";

const hr = 60 * 60 * 1000;

export function createRelayEnvironment(getToken: () => string | null) {
  const cache = new QueryResponseCache({ size: 500, ttl: hr });
  return new Environment({
    network: Network.create(createFetchQuery(cache, getToken)),
    store: new Store(new RecordSource())
  });
}

function createFetchQuery(cache: any, getToken: () => string | null) {
  return function(operation: any, variables: any, cacheConfig: any) {
    const queryID = operation.text;
    const isMutation = operation.operationKind === "mutation";
    const isQuery = operation.operationKind === "query";
    const forceFetch = cacheConfig && cacheConfig.force;

    // Try to get data from cache on queries
    const fromCache = cache.get(queryID, variables);
    if (isQuery && fromCache !== null && !forceFetch) {
      return fromCache;
    }

    const token = getToken();

    // Otherwise, fetch data from server
    return fetch(process.env.REACT_APP_API_BASE_URL + "/graphql", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token ? `Bearer ${token}` : ""
      },
      body: JSON.stringify({
        query: operation.text,
        variables
      })
    })
      .then(response => {
        // TODO?
        if (response.status === 401 && typeof window !== "undefined") {
          window.alert("You've been logged out. Please log in again.");
          window.localStorage.clear();
          window.location.href = "/login";
        }
        return response.json();
      })
      .then(json => {
        // Update cache on queries
        if (isQuery && json) {
          cache.set(queryID, variables, json);
        }
        // Clear cache on mutations
        if (isMutation) {
          cache.clear();
        }

        return json;
      });
  };
}
