export const isAuthenticated = () => !!window.localStorage.getItem("apiToken");

export const getToken = () => window.localStorage.getItem("apiToken");

export const setToken = (token: string) => {
  window.localStorage.setItem("apiToken", token);
};

export const removeToken = () => {
  window.localStorage.removeItem("apiToken");
};
