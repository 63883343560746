import * as React from "react";
import { useState } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { RelayEnvironmentContext } from "./RelayEnvironmentContext";
import { createRelayEnvironment } from "./shared/createRelayEnvironment";
import { isAuthenticated, getToken } from "./shared/auth";
import "@reach/dialog/styles.css";
import "./App.css";

const login = React.lazy(() => import("./pages/login"));
const signup = React.lazy(() => import("./pages/signup"));
const index = React.lazy(() => import("./pages/index"));
const results = React.lazy(() => import("./pages/results"));
const result = React.lazy(() => import("./pages/result"));
const checkout = React.lazy(() => import("./pages/checkout"));
const confirmation = React.lazy(() => import("./pages/confirmation"));
const appointments = React.lazy(() => import("./pages/appointments"));
const procedures = React.lazy(() => import("./pages/procedures"));
const physicians = React.lazy(() => import("./pages/physicians"));
const facilities = React.lazy(() => import("./pages/facilities"));
const messages = React.lazy(() => import("./pages/messages"));
const message = React.lazy(() => import("./pages/message"));
const patientNavigationHome = React.lazy(() =>
  import("./pages/patient_navigation_home")
);
const patientNavigationItem = React.lazy(() =>
  import("./pages/patient_navigation_item")
);
const prescription = React.lazy(() => import("./pages/prescription"));
const teleDoc = React.lazy(() => import("./pages/tele-doc"));
const account = React.lazy(() => import("./pages/account"));
const accountInsurance = React.lazy(() => import("./pages/account-insurance"));
const accountPhysicians = React.lazy(() =>
  import("./pages/account-physicians")
);
const accountPayment = React.lazy(() => import("./pages/account-payment"));
const changePassword = React.lazy(() => import("./pages/change-password"));
const notFound = React.lazy(() => import("./pages/404"));

function App() {
  const [relay_environment, set_relay_environment] = useState(
    createRelayEnvironment(getToken)
  );

  return (
    <RelayEnvironmentContext.Provider
      value={{ relay_environment, set_relay_environment }}
    >
      <BrowserRouter>
        <React.Suspense fallback={null}>
          <Switch>
            <LoginOrSignupRoute exact path="/login" component={login} />
            <LoginOrSignupRoute exact path="/signup" component={signup} />
            <PrivateRoute exact path="/" component={index} />
            <PrivateRoute exact path="/results" component={results} />
            <PrivateRoute exact path="/p" component={result} />
            <PrivateRoute exact path="/checkout" component={checkout} />
            <PrivateRoute exact path="/confirmation" component={confirmation} />
            <PrivateRoute exact path="/appointments" component={appointments} />
            <PrivateRoute exact path="/procedures" component={procedures} />
            <PrivateRoute exact path="/physicians" component={physicians} />
            <PrivateRoute exact path="/facilities" component={facilities} />
            <PrivateRoute exact path="/messages" component={messages} />
            <PrivateRoute exact path="/message" component={message} />
            <PrivateRoute exact path="/account" component={account} />
            <PrivateRoute
              exact
              path="/account/insurance"
              component={accountInsurance}
            />
            <PrivateRoute
              exact
              path="/account/physicians"
              component={accountPhysicians}
            />
            <PrivateRoute
              exact
              path="/account/payment"
              component={accountPayment}
            />
            <PrivateRoute
              exact
              path="/change-password"
              component={changePassword}
            />
            <PrivateRoute
              exact
              path="/medical-attention"
              component={patientNavigationHome}
            />
            <PrivateRoute
              exact
              path="/medical-attention-item"
              component={patientNavigationItem}
            />
            <PrivateRoute exact path="/prescription" component={prescription} />
            <PrivateRoute exact path="/tele-doc" component={teleDoc} />
            <Route component={notFound} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </RelayEnvironmentContext.Provider>
  );
}

// If already authenticated, redirect to `/` or `to`
function LoginOrSignupRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (!isAuthenticated()) {
          return <Component {...props} />;
        }
        const search_params = new URLSearchParams(props.location.search);
        const redirectKey = "to";
        const redirectLocation = search_params.get(redirectKey) || "/";
        return <Redirect to={redirectLocation} />;
      }}
    />
  );
}

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        return isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        );
      }}
    />
  );
}

export default App;
