import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

if (process.env.NODE_ENV !== "production") {
  // @ts-ignore
  window.__APP_DATA__ = {
    primaryColor: "#184272",
    displayName: "HealthQRS Marketplace",
    logoURL: "https://s3.amazonaws.com/hqrs.media/brands/logo-m-hqrs.png"
  };
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
